.orientation-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/bg art.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #438E0A;
  z-index: 10;
}

.orientation-text {
  color: white;
  text-align: center;
  font-family: Meloriac, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: normal;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

.orientation-phone {
  position: absolute;
  width: 230px;
  height: 126px;
  top: 165px;
  left: calc(50% - 115px);
  animation: rotate 5s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  40%,
  50% {
    transform: rotate(90deg);
  }
  90% {
    transform: rotate(0deg);
  }
}

.loader {
  background-image: url("../../assets/bg art.png"),
    radial-gradient(50.55% 110.23% at 50% 50%, #438E0A 0%, #1F520D 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  object-position: top center;
  background-color: #438E0A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-spinner-bg {
  background-image: url("../../assets/loader bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-spinner-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 260px;
  background-image: url("../../assets/loader spiner.png");
  animation: rotating 2s linear infinite;
}

.loader-spinner-title {
  position: absolute;
  background-image: url("../../assets/loader title.png");
  width: 418px;
  height: 192px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.loader-char-1,
.loader-char-2 {
  position: fixed;
  pointer-events: none;
  z-index: -1;
}

.loader-char-1 img,
.loader-char-2 img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-height: 700px) and (orientation: portrait) {
  .loader-char-1,
  .loader-char-2 {
    bottom: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .loader {
    background-image: url("../../assets/bg_art-mobile.png"),
    radial-gradient(50.55% 110.23% at 50% 50%, #438E0A 0%, #1F520D 100%);
    background-size: contain;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

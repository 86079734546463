@font-face {
  font-family: 'DIN Condensed';
  src: url('assets/fonts/din.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Meloriac';
  src: url('assets/fonts/meloriac.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body, html {
  touch-action: none;
  margin: 0;
  max-height: 100%;
  height: 100%;
  width: 100%;
  background: #0f1015;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.fill-window {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.sign-up {
  text-transform: uppercase;
}

.form {
  display: flex;
  flex-direction: column;
}

.form__title {
  margin-top: 0;
  color: white;
  text-align: center;
  font-family: Meloriac, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.inputs input {
  width: 400px;
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFE7B6;
  box-shadow: 0 -4px 0 0 rgba(255, 255, 255, 0.75) inset, 0 4px 0 0 #FFCF6D inset;
  color: #000;
  text-align: center;
  font-family: "DIN Condensed", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.inputs input::placeholder {
  color: #000;
}

.inputs input:hover {
  filter: brightness(90%);
}

.inputs input:active {
  filter: brightness(80%);
}

.inputs input:focus {
  outline: none;
}

.form .action,
.form .mb {
  margin-bottom: 30px;
}

.text.error {
  text-decoration: none;
}

button.action {
  width: 400px;
  border-radius: 10px;
  border: 1px solid #000;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #000 100%), #FFDA00;
  background-blend-mode: overlay, normal;
  /* shadows */
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.25),
  0 2px 0 0 #FFF inset,
  0 -4px 0 0 #FF7100 inset;

  color: #fff;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  font-family: Meloriac, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

button.action:hover {
  filter: brightness(90%);
}

button.action:focus {
  filter: brightness(80%);
}

button.action span {
  filter: drop-shadow(0 4px 0 black);
}

button.text {
  margin: 0 auto;
  background: none;
  border: none;
  outline: none;
  color: white;
  font-family: "DIN Condensed", Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration-line: underline;
}

.error-text {
  margin: 0;
  color: #ffb100;
  text-align: center;
  font-family: "DIN Condensed", Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.toggle {
  position: absolute;
  top: 50%;
  right: 20px;
  margin: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.promo-container {
  position: relative;
}

.promo-container img {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
}

.popup {
  position: absolute;
  top: -25px;
  right: -351px;
  width: 100%;
  max-width: 328px;
  padding: 20px;
  border-radius: 10px;
  background: #FFE7B6;
  box-shadow: 0 -2px 0 0 #FFF inset, 0 2px 0 0 rgba(0, 0, 0, 0.50) inset;
  z-index: 2;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.popup.open {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
}

.popup h3 {
  margin: 0 0 10px;
  color: #ffffff;
  text-shadow: 0 2px 0 #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: Meloriac, Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.popup p {
  margin: 0;
  color: #000;
  font-family: "DIN Condensed", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}


.header-title {
  margin: 22px auto 60px;
  font-family: Meloriac, Arial, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 57px;
  text-align: center;
  color: #FFDA00;
}

@media screen and (orientation: landscape) and (max-height: 800px) {
  .header-title {
    margin: 2vh auto;
    font-size: 5vh;
  }

  .form__title {
    margin-bottom: 2vh !important;
    font-size: 5vh !important;
  }

  .logo img {
    width: auto !important;
    height: 15vh;
  }

  input, .action {
    height: 10vh !important;
    font-size: 5vh !important;
  }

  .inputs {
    margin-bottom: 2vh !important;
  }
}

@media screen and (orientation: portrait) {
  .form {
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
    margin-bottom: auto;
  }

  button.action,
  .inputs input {
    width: 100%;
  }

  .form__title {
    margin-bottom: 20px;
    text-shadow: 0 2px 0 #000;
  }

  button {
    margin-bottom: 20px !important;
  }

  .promo-container img {
    right: 10px;
    width: 33px;
    height: 33px;
  }

  .popup {
    top: -136px;
    right: 0;
    transform: translateY(100px);
  }

  .popup.open {
    transform: translateY(0);
  }

  .header-title {
    display: none;
  }
}
